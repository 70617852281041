<template>
  <div
    ref="booking"
    class="booking-item"
    :class="{ '--archive': isArchived }"
    :style="booking.style"
    @click="$emit('open-usage-info')"
    @mousedown="startDrag"
  >
    <span
      v-if="!isArchived"
      class="booking-item__stretch --left"
      :class="{ disabled: !booking.isVisibleStartDate }"
      @mousedown="startResize('left')"
    ></span>
    <span class="client-name">{{ clientName }}</span>
    <span
      v-if="!isArchived"
      class="booking-item__stretch --right"
      :class="{ disabled: !booking.isVisibleEndDate }"
      @mousedown="startResize('right')"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'BookingItem',

  props: {
    booking: {
      type: Object,
      required: true,
    },
    isArchived: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    clientName() {
      const fixedWidth = Number(parseFloat(this.booking.style?.width).toFixed()) - 10;
      const lettersWidth = 10;

      const firstNameLength = this.booking.firstName.length * lettersWidth;
      const lastNameLength = this.booking.lastName.length * lettersWidth;

      if (firstNameLength + lastNameLength < fixedWidth) {
        return `${this.booking.firstName} ${this.booking.lastName}`;
      }

      if (firstNameLength + lettersWidth < fixedWidth) {
        return `${this.booking.firstName} ${this.booking.lastName && `${this.booking.lastName[0]}.`}`;
      }

      if (lettersWidth + lettersWidth < fixedWidth) {
        return `${this.booking.firstName[0]}. ${this.booking.lastName && `${this.booking.lastName[0]}.`}`;
      }

      return `${this.booking.firstName[0]}.`;
    },
  },

  methods: {
    startDrag(evt) {
      this.$emit('start-drag', { evt, booking: this.booking });
    },

    startResize(side) {
      this.$emit('start-resize', { side, booking: this.booking });
    },
  },
};
</script>

<style lang="scss">
.booking-item {
  pointer-events: all;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  top: 50%;
  transform: translate(0, -50%);
  height: calc(100% - 20px);
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(106, 137, 239);
  color: #ffffff;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;

  &:hover {
    background: rgba(106, 137, 239, 0.3);
    color: #002fff;
  }

  &.--archive {
    background: rgb(142, 142, 142);

    &:hover {
      background: rgba(142, 142, 142, 0.3);
      color: rgb(142, 142, 142);
    }
  }

  .client-name {
    pointer-events: none;
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__stretch {
    flex: 0 0 5px;
    width: 10px;
    height: 100%;
    cursor: col-resize;

    &.disabled {
      pointer-events: none;
    }
  }
}
</style>

<template>
  <div v-if="media.isMobile" class="d-flex flex-column align-center justify-center" style="height: 100%">
    <imageDataMissing class="calendar-mobile-image mb-4" />
    {{ $t('calendar.mobile_message') }}
  </div>
  <div v-else class="calendar-page">
    <create-booking-modal ref="createBookingModal" :booking="initialData" @add-new-booking="addNewBooking" />

    <div class="d-flex align-ceenter justify-space-between mb-4">
      <div class="page-title d-flex align-center mb-0">
        <span>{{ $t('navigation.calendar') }}</span>
        <v-btn elevation="0" color="#EEEEEE" class="ms-6" @click="openModalCreateBooking">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <filters
      v-model="filtersData"
      :schema="$options.schema"
      :modal-schema="$options.modalSchema"
      :options.sync="options"
      :sort-list="sortList"
      :sort-by="activeSort"
    />

    <calendar-graph
      ref="graph"
      :dates="dates"
      :units="units"
      :total-units="totalUnits"
      :units-count="unitsCount"
      :is-loading="isLoading"
      :cell-height="56"
      :cell-min-width="32.65"
      :show-days-without-rental="showDaysWithoutRental"
      @change-month="changeDate"
      @open-modal-for-create="openModalCreateBooking"
      @update-calendar-list="debounceGetList"
      @open-usage-info="openUsageInfo"
    />

    <calendar-modal ref="usageInfo" />
  </div>
</template>

<script>
import imageDataMissing from '@/assets/imageMissingData/missing-data-tasks.svg';
import {
  format,
  eachDayOfInterval,
  parseISO,
  getISODay,
  isToday,
  startOfMonth,
  endOfMonth,
  isDate,
  isAfter,
} from 'date-fns';
// Components
import Filters from '@/components/Calendar/Filters.vue';
import CalendarGraph from '@/components/Calendar/Graph.vue';
import CreateBookingModal from '@/components/Calendar/CreateBookingModal.vue';
import CalendarModal from '@/components/Calendar/CalendarModal.vue';

// Models
import { createModelData } from '@/schemas/createModelData';
import { schema, modalSchema } from '@/schemas/calendarFilter.schema';

// Utils
import { clone } from '@/utils/clone';
import { debounce } from '@/utils/delay';
import { flushPromises } from '@/utils/scheduler';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import { translateShortWeek } from '@/utils/dateFormatting';

import realtyService from '@/services/realty';

// Services
import client from '@/http/client';
import calendarService from '@/services/calendar';

export default {
  name: 'Calendar',

  components: {
    // SchemaFormFieldMonthDate,
    Filters,
    CalendarGraph,
    CreateBookingModal,
    imageDataMissing,
    CalendarModal,
  },

  inject: ['media'],

  provide() {
    return {
      updateBooking: this.updateBooking,
      archiveBooking: this.archiveBooking,
    };
  },

  data() {
    return {
      // Data
      units: [],
      totalUnits: 0,
      unitsCount: 0,
      offset: 0,
      dates: [],
      isLoading: true,
      debounceGetList: null,
      debounceResetAndGetCalendarList: null,

      // Filters
      currentDate: new Date(),
      initialData: null,
      filtersData: createModelData([...schema, ...modalSchema]),
      filters: {
        projects: undefined,
        buildings: undefined,
        units: undefined,
        rentalPeriodAfter: format(startOfMonth(new Date()), 'yyyy-LL-dd'),
        rentalPeriodBefore: format(endOfMonth(new Date()), 'yyyy-LL-dd'),
        status: ['available', 'partly_available'],
        propertyType: undefined,
        numberOfBedrooms: undefined,
        rentCostNotPresent: false,
      },

      // Sort
      options: { sortBy: '', sortDesc: null },
      showDaysWithoutRental: false,
      sortList: [
        {
          text: this.$t('label.days_no_rental'),
          value: 'days_no_rental',
        },
      ],
    };
  },

  computed: {
    formatCurrentDate() {
      return format(this.currentDate, 'yyyy-LL');
    },

    activeSort() {
      return this.sortList.find(sort => sort.value === this.options.sortBy || sort?.sortValue === this.options.sortBy);
    },

    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;
      const sortItem = this.sortList.find(sort => sort.value === this.options.sortBy);
      const value = sortItem?.sortValue || this.options.sortBy;
      if (this.options.sortDesc === null) return value;
      if (this.options.sortDesc === undefined) return undefined;
      return this.options.sortDesc ? `-${value}` : value;
    },
  },

  watch: {
    filtersData: {
      handler(newValue) {
        this.updateFilters(newValue);
      },
      deep: true,
    },

    filters: {
      handler() {
        this.debounceResetAndGetCalendarList();
      },
      deep: true,
    },

    orderBy(val) {
      this.showDaysWithoutRental = !!val?.includes('days_no_rental');
      this.debounceResetAndGetCalendarList();
    },
  },

  created() {
    this.debounceGetList = debounce(this.getCalendarList, 500);
    this.debounceResetAndGetCalendarList = debounce(this.resetAndGetCalendarList, 500);
  },

  mounted() {
    this.updateFilters(this.filtersData);
  },

  methods: {
    resetAndGetCalendarList() {
      this.units = [];
      this.unitsCount = 0;
      this.totalUnits = 0;
      this.offset = 0;
      this.getCalendarList();
    },
    async getCalendarList() {
      if (this.$options.cancelSource) {
        this.$options.cancelSource.cancel();
        await flushPromises();
      }

      this.isLoading = true;
      const cancelSource = client.getCancelToken();
      this.$options.cancelSource = cancelSource;
      const config = { cancelToken: cancelSource.token };

      try {
        const { count, results: unitIds } = await calendarService.getCalendarListUnitIds(
          this.normalizedFilterBody(),
          {
            ...this.normalizedFilterParams(),
            limit: this.limit,
            offset: this.offset,
            orderBy: this.orderBy,
          },
          config
        );

        this.unitsCount += unitIds.length;
        if (this.totalUnits !== count) {
          this.totalUnits = count;
        }
        const { results: units } = await calendarService.getCalendarListFromUnitIds({
          units: JSON.stringify([...unitIds]),
          rentalPeriodBefore: this.filters.rentalPeriodBefore,
          rentalPeriodAfter: this.filters.rentalPeriodAfter,
          orderBy: this.orderBy,
        });
        this.offset += units.length;
        this.normalizeUnits(units);
      } finally {
        this.isLoading = false;
        this.$options.cancelSource = null;
      }
    },

    normalizeUnits(newUnits) {
      let startIndexY = this.units.length ? this.units.length : 0;

      newUnits.forEach(unit => {
        this.units.push({ ...unit, type: 'unit', index: startIndexY });
        startIndexY += 1;
        unit.rooms.forEach(room => {
          this.units.push({ ...room, type: 'room', index: startIndexY });
          startIndexY += 1;
        });
      });
    },

    updateFilters(filters) {
      if (filters.projects) {
        this.filters.projects = clone(filters.projects);
      }

      if (filters.buildings) {
        this.filters.buildings = clone(filters.buildings);
      }

      if (filters.units) {
        this.filters.units = clone(filters.units);
      }

      if (filters.period.rentalPeriodAfter && filters.period.rentalPeriodBefore) {
        this.filters.rentalPeriodAfter = filters.period.rentalPeriodAfter;
        this.filters.rentalPeriodBefore = filters.period.rentalPeriodBefore;
        this.setDates();
      }

      if (filters.period.status) {
        this.filters.status = clone(filters.period.status);
      }

      if (filters.amountGoal) {
        this.filters.goalRentCostMin = +filters.amountGoal.min;
        this.filters.goalRentCostMax = +filters.amountGoal.max;
        this.filters.rentCostNotPresent = filters.amountGoal.rentCostNotPresent;
      }

      if (filters.unitType) {
        this.filters.propertyType = filters.unitType.type;
        this.filters.numberOfBedrooms = filters.unitType.bedrooms;
      }
    },

    normalizedFilterParams() {
      return {
        status: this.filters.status.join(','),
        rentalPeriodBefore: this.filters.rentalPeriodBefore,
        rentalPeriodAfter: this.filters.rentalPeriodAfter,
        goalRentCostMin: (!this.filters.rentCostNotPresent && this.filters.goalRentCostMin) || undefined,
        goalRentCostMax: (!this.filters.rentCostNotPresent && this.filters.goalRentCostMax) || undefined,
        rentCostNotPresent: this.filters.rentCostNotPresent || undefined,
        propertyType: this.filters.propertyType,
        numberOfBedrooms:
          this.filters.propertyType === 'apartment' && this.filters.numberOfBedrooms?.length
            ? this.filters.numberOfBedrooms.join(',')
            : undefined,
      };
    },

    normalizedFilterBody() {
      return {
        projects: checkEmptyParams(this.filters?.projects)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.projects),
        buildings: checkEmptyParams(this.filters?.buildings)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.buildings),
        units: checkEmptyParams(this.filters?.units)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.units),
      };
    },

    async openModalCreateBooking(data) {
      if (data.unitId) {
        const isUnit = data.unitType === 'unit';
        const unit = isUnit
          ? await realtyService.getUnitById(data.unitId)
          : await realtyService.getRoomById(data.unitId);

        if (isUnit) {
          this.initialData = {
            rentalPeriod: data.rentalPeriod,
            unit: {
              id: unit.id,
              name: unit.name,
            },
            building: unit.building,
            project: unit.project,
          };
        } else {
          this.initialData = {
            rentalPeriod: data.rentalPeriod,
            room: {
              id: unit.id,
              name: unit.name,
            },
            unit: unit.parentUnit,
            building: unit.building,
            project: unit.project,
          };
        }
      }

      this.$refs.createBookingModal.open();
    },

    addNewBooking(data) {
      const booking = {
        clientId: data.clientId,
        firstName: data.firstName,
        lastName: data.lastName,
        rentalPeriod: {
          lower: format(new Date(data.rentalPeriod.lower), 'yyyy-L-dd'),
          upper: format(new Date(data.rentalPeriod.upper), 'yyyy-L-dd'),
        },
        usageId: data.usageId,
        unit: data.unit,
        room: data.room,
      };

      this.$refs.graph.addNewBooking(booking);
    },

    changeDate(date) {
      this.currentDate = isDate(date) ? date : new Date(date);
    },

    setDates() {
      this.dates = [];
      const startDate = this.filters.rentalPeriodAfter;
      const endDate = this.filters.rentalPeriodBefore;

      this.dates = eachDayOfInterval({
        start: parseISO(startDate),
        end: parseISO(endDate),
      }).map(date => ({
        id: `${format(date, 'yyyy-MM-dd')}`,
        day: +format(date, 'd'),
        isToday: isToday(date),
        week: translateShortWeek(getISODay(date)),
        value: date,
      }));
    },

    openUsageInfo([usageId, index]) {
      this.$refs.usageInfo.open(usageId, index);
    },

    updateBooking(index, bookingData) {
      const unit = this.units[index];

      if (unit.id === (bookingData.room?.id || bookingData.unit.id)) {
        const booking = unit.bookings.filter(item => {
          return item.usageId === bookingData.usageId;
        })[0];

        booking.rentalPeriod = bookingData.rentalPeriod;
      } else {
        let newIndex;
        unit.bookings = unit.bookings.filter(item => item.usageId !== bookingData.usageId);
        for (let i = 0; i < this.units.length; i += 1) {
          if (this.units[i].id === (bookingData.room?.id || bookingData.unit.id)) {
            this.units[i].bookings.push({
              clientId: bookingData.clientId,
              firstName: bookingData.firstName,
              lastName: bookingData.lastName,
              rentalPeriod: bookingData.rentalPeriod,
              usageId: bookingData.usageId,
            });

            newIndex = this.units[i].index;
            break;
          }
        }
        return newIndex;
      }
      return null;
    },

    archiveBooking(index, booking) {
      const unit = this.units[index];
      const { rentalPeriodStartDate, rentalPeriodEndDate } = booking;

      if (isAfter(new Date(rentalPeriodStartDate), new Date())) {
        unit.bookings = unit.bookings.filter(item => item.usageId !== booking.usageId);
        return;
      }

      if (isAfter(new Date(rentalPeriodEndDate), new Date())) {
        const archiveBooking = {
          clientId: booking.clientId,
          firstName: booking.firstName,
          lastName: booking.lastName,
          rentalPeriod: {
            lower: rentalPeriodStartDate,
            upper: format(new Date(), 'yyyy-L-dd'),
          },
          usageId: booking.usageId,
        };

        unit.bookings = unit.bookings.filter(item => item.usageId !== booking.usageId);
        unit.archivedBookings.push(archiveBooking);
      }
    },
  },

  cancelSource: null,
  notificationItem: null,
  schema,
  modalSchema,
};
</script>

<style lang="scss">
.calendar-page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>

/* eslint-disable */
import client from '@/http/client';

const BASE_URL = '/realty/calendar';

class CalendarService {
  // OLD
  async getCalendarList(bodyRequest, params, config) {
    return client.post(`${BASE_URL}/`, bodyRequest, { ...config, params })
  }

  async getCalendarListUnitIds(bodyRequest, params, config) {
    return client.post(`${BASE_URL}/list-simple/`, bodyRequest, { ...config, params })
  }

  async getCalendarListFromUnitIds(params, config) {
    return client.get(`${BASE_URL}/`, { ...config, params })
  }

  async createBooking(bodyRequest) {
    return client.post(`${BASE_URL}/bookings/`, bodyRequest)
  }

  async getBookingInfo(bookingId) {
    return client.get(`${BASE_URL}/bookings/${bookingId}/`)
  }

  async updateBooking(bodyRequest, bookingId) {
    return client.put(`${BASE_URL}/bookings/${bookingId}/`, bodyRequest)
  }
}

export default new CalendarService();